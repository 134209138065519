import React from "react"
import projectJSON from "../projects.json"
import feature from "../images/feature.png"
import SectionHeader from "./section-header"
import Image from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"

const Content = () => {
  return (
    <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
      <SectionHeader
        title="Projects"
        description="If it has helped them, it could help you too...."
      />

      <section className="projects__container">
        {projectJSON.map(p => (
          <div className="project" key={p.title + p.description}>
            <h2 className="project__title">{p.title}</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flex: "1 1 auto",
              }}
            >
              <div className="project__description">{p.description}</div>
              <div className="project__actions">
                <div className="project__links">
                  <a target="_blank" href={p.github}>
                    Github
                  </a>
                  <a target="_blank" href={p.npm}>
                    NPM
                  </a>
                </div>
                <div className="project__author" title={p.author.name}>
                  <a target="_blank" href={p.author.url}>
                    {/*<img src={feature} />*/}
                    {
                      // Find a way to make the avatar dynamic for many authors
                      <StaticQuery
                        query={graphql`
                          query {
                            file(relativePath: { eq: "authors/aleem.png" }) {
                              childImageSharp {
                                # Specify the image processing specifications right in the query.
                                # Makes it trivial to update as your page's design changes.
                                fluid(maxWidth: 50) {
                                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                                  ...GatsbyImageSharpFluid_noBase64
                                }
                              }
                            }
                          }
                        `}
                        render={data => {
                          return (
                            <Image fluid={data.file.childImageSharp.fluid} />
                          )
                        }}
                      />
                    }
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* <div>
        <img src={feature} alt="a blank card floating over colorful graphics" />
      </div> */}
      </section>
    </div>
  )
}

export default Content
